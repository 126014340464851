// Footer.tsx
import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  return (
      <>
        <FooterSection>
          <Container>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <Language>Kontakt</Language>
                <Large to="/">Erzählen Sie uns Alles</Large>
                <Para>
                  Haben Sie Fragen? Zögern Sie nicht, uns zu kontaktieren.
                </Para>
                <a href="mailto:info@heinz-consulting.de">
                  <Chat>Lassen Sie uns chatten</Chat>
                </a>
              </Col>
              <>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <Title>Richtlinien</Title>
                  <Large to="/">Anwendungssicherheit</Large>
                  <Large to="/">Software-Prinzipien</Large>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <Empty/>
                  <Large to="/">Support-Center</Large>
                  <Large to="/">Kundensupport</Large>
                </Col>
              </>
            </Row>
            <Row justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <Empty />
                <Language>Adresse</Language>
                <Para>Knollstraße 17</Para>
                <Para>72072 Tübingen</Para>
                <Para>Deutschland</Para>
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Title>Unternehmen</Title>
                <Large to="/">Über uns</Large>
                <Large to="/">Blog</Large>
                <Large to="/">Presse</Large>
                <Large to="/">Karriere & Kultur</Large>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Label htmlFor="select-lang">Sprache</Label>
                <LanguageSwitchContainer>
                  <LanguageSwitch>
                    <SvgIcon
                        src="germany.svg"
                        aria-label="Deutsche Version"
                        width="30px"
                        height="30px"
                    />
                  </LanguageSwitch>
                </LanguageSwitchContainer>
              </Col>
            </Row>
          </Container>
        </FooterSection>
        <Extra>
          <Container border={true}>
            <Row
                justify="space-between"
                align="middle"
                style={{ paddingTop: "3rem" }}
            >
              <NavLink to="/">
                <LogoContainer>
                  <SvgIcon
                      src="logo.svg"
                      aria-label="homepage"
                      width="300px"
                      height="64px"
                  />
                </LogoContainer>
              </NavLink>
              <FooterContainer>
                {/* Hier können Sie später Ihre Social-Media-Icons hinzufügen */}
              </FooterContainer>
            </Row>
          </Container>
        </Extra>
      </>
  );
};

export default withTranslation()(Footer);